




































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import DoctorAppointmentService from "../../service/DoctorAppointmentService";
import { IDoctorAppointment } from "../../models/DoctorAppointment";
import BookAppointmentNew from "@/doctor-app/components/BookAppointmentNew.vue";
import BookAppointmentOld from "@/doctor-app/components/BookAppointmentOld.vue";
import DoctorChamberService from "../../service/DoctorChamberService";
import { IDoctorChamber } from "../../models/DoctorChamber";
import DoctorScheduleService from "../../../doctor-app/service/DoctorScheduleService";
import { IDoctorSchedule } from "../../../doctor-app/models/DoctorSchedule";

@Component({
  components: {
    BookAppointmentNew,
    BookAppointmentOld,
  },
})
export default class Calendar extends Vue {
  myDate: any = null;
  public appointments: IDoctorAppointment[] = [];
  highlighted: any = {};
  public currentDate: Date = new Date();
  public showAppoitmentNew: boolean = false;
  public showAppoitmentOld: boolean = false;
  public doctorSchedule: IDoctorSchedule;
  public chamberId: any = 0;
  public scheduleId: any = 0;
  public chambers: IDoctorChamber[] = [];
  public schedules: IDoctorSchedule[] = [];
  public WeeklyDate: any = [];
  public weekDay: any = [];
  public currentDay: Date;
  public isSchedule: any = 0;
  public showAppointmentType:boolean=false;
  public isExistsSchedule:any=[];
  created() {
    DoctorAppointmentService.daysOfMonth(new Date().getMonth() + 1).then((res) => {
      this.highlighted = { daysOfMonth: res.data };
    });

    DoctorChamberService.GetListByUser().then((res) => {
      if (res.data.result) {
        this.chambers = res.data.result || [];
        //this.$store.dispatch("addChamberList", res.data.result);
        this.isSchedule = res.data.result;
      }
    });

    DoctorAppointmentService.getAptScheduleRefresh().subscribe((res) => {
      if (res) {
        this.onChangeSchedule();
      }
    });

    /*
      DoctorScheduleService.GetScheduleList().then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
        //console.log(this.schedules);
        //this.$store.dispatch("addChamberList", res.data.result);
      }
    });
    */

    //console.log(this.timeFrom());
    this.timeFrom();
    // this.isSchedule = this.chambers.find((s) => s.id == this.chamberId)!.avgConsultTime || 0;

  }

mounted(){
    DoctorScheduleService.GetScheduleList(this.chamberId, this.currentDate).then((res) => {
      if (res.data.result) {
        this.schedules = [];
        this.schedules = res.data.result || [];
        //this.$store.dispatch("addChamberList", res.data.result);
        this.chamberId = this.schedules[0].chamberId;
        
        DoctorScheduleService.GetListByUser(this.chamberId)
      .then((res) => {
        this.isExistsSchedule = res.data.result || [];
      });
      }
    });

}
  

  formatter(date: any) {
    var last = new Date(date.getTime());
    var day = last.getDate() - 1;
    var month = last.getMonth() + 1;
    var year = last.getFullYear();
    var fulld = "";
    if (day < 10) {
      fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
    } else if (month < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
    } else if (month < 10 && day < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
    } else {
      fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
    }

    return fulld;
  }
  add(e: Date) {
    DoctorAppointmentService.getByAppointmentDate(e).then((res) => {
      this.appointments = res.data;
    });
  }
  changedMonth(e: Date) {
    DoctorAppointmentService.daysOfMonth(e.getMonth() + 1).then((res) => {
      this.highlighted = { daysOfMonth: res.data };
    });
  }

  closeModal(value: any) {
    this.showAppoitmentNew = value;
  }
  closeOldBA(value: any) {
    this.showAppoitmentOld = value;
  }

  onChangeSchedule() {
    this.isSchedule = [];
    DoctorScheduleService.GetScheduleList(this.chamberId, this.currentDate).then((res) => {
      this.schedules = [];
      if (res.data.result) {
        this.schedules = res.data.result || [];
        this.isSchedule = res.data.result;
      }
    });

        DoctorScheduleService.GetListByUser(this.chamberId)
      .then((res) => {
        this.isExistsSchedule = res.data.result || [];
      });
  }

  onChange(event: any) {
    this.chamberId = event.target.value;
    this.onChangeSchedule();
  }

  findSchedule(value: any) {
    if (value == 1) {
      var increment = new Date(this.currentDate.getTime() + 1 * 24 * 60 * 60 * 1000);
      this.currentDate = increment;
    } else if (value == -1) {
      var decrement = new Date(this.currentDate.getTime() - 1 * 24 * 60 * 60 * 1000);
      this.currentDate = decrement;
    } else if (value == 7) {
      decrement = new Date(this.currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
      this.currentDate = decrement;
    } else if (value == -7) {
      decrement = new Date(this.currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      this.currentDate = decrement;
    } else if (value == 30) {
      decrement = new Date(this.currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
      this.currentDate = decrement;
    }
    this.onChangeSchedule();
    this.changedTimeFrom(this.currentDate);

    //alert(this.currentDate);
  }

  getScheduleId(value: any, value2: any) {
    this.scheduleId = value;
    this.currentDay = value2;
    this.showAppointmentType=true;
  }

  changedTimeFrom(value: any) {
    this.WeeklyDate = [];
    this.weekDay = [];
    var days = 7;
    for (var i = 0; i < days; i++) {
      if (i == 0) {
        i = 1;
        days += 1;
      }
      var date = value;
      var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);

      var day = last.getDate() - 1;
      var month = last.getMonth() + 1;
      var year = last.getFullYear();
      if (day == 0) {
        last = new Date(date.getTime() + (i - 1) * 24 * 60 * 60 * 1000);
        day = last.getDate();
        month = last.getMonth() + 1;
        year = last.getFullYear();
      }

      if (day < 10) {
        const fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else {
        const fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      }
      var weekdays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];
      var day2 = weekdays[last.getDay()];

      if (day == 30 || day == 31 || day == 28 || day == 29) {
        last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
        day2 = weekdays[last.getDay()];
      }
      this.weekDay.push(day2);
    }
    // console.log(this.WeeklyDate);
    // console.log(this.weekDay);
  }

  timeFrom() {
    var days = 7;
    for (var i = 0; i < days; i++) {
      if (i == 0) {
        i = 1;
        days += 1;
      }
      var date = new Date();
      var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);

      var day = last.getDate() - 1;
      var month = last.getMonth() + 1;
      var year = last.getFullYear();

      if (day < 10 && month > 9) {
        const fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day > 9) {
        const fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else {
        const fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      }

      var weekdays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];
      var day2 = weekdays[last.getDay()];
      // alert(last.getDay());
      this.weekDay.push(day2);
      //alert(day2);
    }
    //console.log(this.weekDay);
    //console.log(this.WeeklyDate);
  }

  formatDate(value: any) {
    var fulld = "";
    var last = new Date(value);
    var day = last.getDate();
    var month = last.getMonth() + 1;
    var year = last.getFullYear();

    if (day < 10) {
      fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
    } else if (month < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
      this.WeeklyDate.push(fulld);
    } else if (month < 10 && day < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
      this.WeeklyDate.push(fulld);
    } else {
      fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
      this.WeeklyDate.push(fulld);
    }
    //console.log(fulld);
    return fulld;
  }
  onchangeCalendar(){
    this.$nextTick(() => this.onChangeSchedule());
    this.$nextTick(() => this.changedTimeFrom(this.currentDate));
    // this.onChangeSchedule();
    // this.changedTimeFrom(this.currentDate);
  }
}
